var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");
'use strict';var jsa=function(a){var b=i6;return $CLJS.$F(function(c){return function(d,e,f){f=b.g?b.g(f):b.call(null,f);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)},k6=function(a){var b=j6;return $CLJS.$F(function(c){return function(d,e,f){e=b.g?b.g(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)},l6=function(a){if($CLJS.de(a))return a;throw $CLJS.zj("Invalid pattern: don't know how to handle symbol.",new $CLJS.k(null,1,[$CLJS.nk,a],null));},m6=function(a){var b=$CLJS.ne(a);return b?
(b=!$CLJS.Ig(a))?$CLJS.Mm.h($CLJS.pf,$CLJS.Jb)($CLJS.C(a)):b:b},j6=function(a){return $CLJS.m($CLJS.Mm.h($CLJS.pf,$CLJS.Jb)(a))?$CLJS.K_(a):a},n6=function(a,b){var c=m6(b);return $CLJS.m(c)?(b=j6($CLJS.C(b)),$CLJS.ke(a)?(a=$CLJS.si(a),a.g?a.g(b):a.call(null,b)):$CLJS.F.h(a,b)):c},ksa=function(a){return $CLJS.M.j(new $CLJS.k(null,3,[$CLJS.Pj,$CLJS.Ye,$CLJS.Wk,j6,$CLJS.dJ,j6],null),a,function(b){var c=$CLJS.M,d=c.h;b=$CLJS.Pf([a,b]);b=o6.h?o6.h(b,p6):o6.call(null,b,p6);return d.call(c,b,a)})},lsa=function(a){a=
$CLJS.oh.j($CLJS.P,$CLJS.rg.g(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);c=j6(c);var d=ksa(c);return new $CLJS.S(null,2,5,$CLJS.T,[c,d.g?d.g(b):d.call(null,b)],null)}),a);return $CLJS.F.h($CLJS.Wk.g(a),$CLJS.dI)&&$CLJS.Gb($CLJS.dJ.g(a))?$CLJS.U.j(a,$CLJS.dJ,$CLJS.ER):a},msa=function(a){return $CLJS.oh.j($CLJS.P,$CLJS.rg.g(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);c=$CLJS.f_(c);return new $CLJS.S(null,2,5,$CLJS.T,[c,$CLJS.U.j(lsa(b),$CLJS.V,c)],null)}),a)},q6=function(a){return $CLJS.r_.j($CLJS.r_.j($CLJS.Sb(function(b,
c){return $CLJS.r_.j(b,c,$CLJS.Oi)},a,new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.uJ,$CLJS.dga,$CLJS.CP,nsa,$CLJS.sF,$CLJS.Wj],null)),$CLJS.KG,$CLJS.Km.h(i6,o6)),$CLJS.eP,$CLJS.YZ)},r6=function(a){return $CLJS.Ae(a)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.EQ,a,null],null):a},t6=function(a){return s6.g(r6(a))},u6=function(a){var b=$CLJS.A(a);a=$CLJS.C(b);b=$CLJS.D(b);return $CLJS.h_($CLJS.oh.h(new $CLJS.S(null,1,5,$CLJS.T,[a],null),$CLJS.rg.h(s6,b)))},v6=function(a){var b=$CLJS.A(a);a=$CLJS.C(b);var c=$CLJS.D(b);
b=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[a,t6(b)],null),$CLJS.rg.h(s6,c))},w6=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.m(a)?new $CLJS.S(null,2,5,$CLJS.T,[b,t6(a)],null):new $CLJS.S(null,1,5,$CLJS.T,[b],null)},x6=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[b,t6(a)],null)},y6=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[b,s6.g(a)],null)},i6=function(a){return z6(function(b){if($CLJS.oe(b))return jsa(b);
if($CLJS.Gb(m6(b)))return b;try{return s6.g(b)}catch(f){if(f instanceof Error){var c=f,d=$CLJS.YF($CLJS.Wu);if($CLJS.m($CLJS.eG("metabase.mbql.normalize",d))){var e=$CLJS.WH("Invalid clause:");e instanceof Error?$CLJS.fG("metabase.mbql.normalize",d,$CLJS.VB.l($CLJS.G([b])),e):$CLJS.fG("metabase.mbql.normalize",d,$CLJS.VB.l($CLJS.G([e,b])),null)}throw $CLJS.yj($CLJS.VH("Invalid MBQL clause: {0}",$CLJS.G([$CLJS.TZ(c)])),new $CLJS.k(null,1,[$CLJS.jS,b],null),c);}throw f;}},a)},osa=function(a){return $CLJS.Mg($CLJS.eg(i6,
A6(a)))},psa=function(a){for(;;)if($CLJS.m(m6(a)))a=new $CLJS.S(null,1,5,$CLJS.T,[a],null);else return $CLJS.Rf($CLJS.Om.h(r6,a))},B6=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.dI);return $CLJS.m(b)?$CLJS.Pm.j(a,$CLJS.dI,s6):a},qsa=function(a){return $CLJS.oh.h($CLJS.P,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);
q=$CLJS.J(q,1,null);u=new $CLJS.S(null,2,5,$CLJS.T,[u,B6(q)],null);l.add(u);n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[l,B6(f)],null),d($CLJS.zd(e)))}return null}},null,null)}(a)}())},C6=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.iR);return $CLJS.m(b)?$CLJS.Pm.j(a,$CLJS.iR,qsa):a},rsa=function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.UQ);b=
$CLJS.Gb(a)?D6.g?D6.g(b):D6.call(null,b):b;return $CLJS.m(a)?C6(b):b},E6=function(a){return $CLJS.ke(a)?$CLJS.A(a):null!=a},ssa=function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.vO);var c=$CLJS.M.h(b,$CLJS.OO),d=$CLJS.M.h(b,$CLJS.OP),e=$CLJS.M.h(b,$CLJS.UQ);try{if($CLJS.m(d)){var f=$CLJS.Qf(b),g=$CLJS.M.h(f,$CLJS.OP);var l=$CLJS.Pu($CLJS.Cm.h(f,$CLJS.OP),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vO,$CLJS.OP],null),g)}else l=b;var n=$CLJS.m(a)?$CLJS.Pm.j(l,$CLJS.vO,D6):l,q=$CLJS.m(c)?$CLJS.Pm.j(n,$CLJS.OO,
$CLJS.bg($CLJS.Om,i6)):n,u=$CLJS.m(e)?$CLJS.Pm.j(q,$CLJS.UQ,C6):q;return i6(u)}catch(v){if(v instanceof Error)throw n=v,$CLJS.yj($CLJS.VH("Error canonicalizing query: {0}",$CLJS.G([$CLJS.TZ(n)])),new $CLJS.k(null,1,[$CLJS.vO,a],null),n);throw v;}},tsa=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.vO),c=$CLJS.Qf(b);b=$CLJS.M.h(c,$CLJS.GP);c=$CLJS.M.h(c,$CLJS.xO);return $CLJS.A(b)&&$CLJS.A(c)?(b=$CLJS.si($CLJS.Ve($CLJS.qg,$CLJS.A($CLJS.ug($CLJS.Hb,function g(e,f){try{if($CLJS.qe(f)&&3===$CLJS.E(f))try{var l=
$CLJS.Td(f,0);if($CLJS.O(l,$CLJS.EQ)){var n=$CLJS.Td(f,1),q=$CLJS.Td(f,2);return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[f,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.EQ,n,$CLJS.Cm.h(q,$CLJS.QQ)],null)],null)],null)}throw $CLJS.Z;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw u;}else throw $CLJS.Z;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)return $CLJS.tZ(g,e,f);throw l;}throw u;}}($CLJS.Lg,b))))),$CLJS.q_.j(a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vO,
$CLJS.xO],null),$CLJS.Km.h($CLJS.Mg,$CLJS.bg($CLJS.Nm,b)))):a},usa=function(a){try{return tsa(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.yj($CLJS.WH("Error performing whole query transformations"),new $CLJS.k(null,1,[$CLJS.vO,a],null),b);}throw c;}},vsa=function(a,b){var c=$CLJS.oh.h($CLJS.ae(a),function(){return function f(e){return new $CLJS.yf(null,function(){for(var g=e;;)if(g=$CLJS.A(g)){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);return function(){for(var y=0;;)if(y<
n){var B=$CLJS.be(l,y),H=$CLJS.J(B,0,null);B=$CLJS.J(B,1,null);var I=$CLJS.kf.h(b,H);B=F6.h?F6.h(B,I):F6.call(null,B,I);null!=B&&q.add(new $CLJS.S(null,2,5,$CLJS.T,[H,B],null));y+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}var u=$CLJS.C(g),v=$CLJS.J(u,0,null),x=$CLJS.J(u,1,null);u=function(){var y=x,B=$CLJS.kf.h(b,v);return F6.h?F6.h(y,B):F6.call(null,y,B)}();if(null!=u)return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[v,u],null),f($CLJS.zd(g)));g=$CLJS.zd(g)}else return null},
null,null)}(a)}());return $CLJS.A(c)?c:null},wsa=function(a,b){a=$CLJS.Om.h(function(c){var d=$CLJS.kf.h(b,G6);return F6.h?F6.h(c,d):F6.call(null,c,d)},a);return $CLJS.m($CLJS.Xf($CLJS.Hb,a))?a:null},z6=function z6(a,b){return $CLJS.WZ($CLJS.bg(z6,a),$CLJS.Ye,a.g?a.g(b):a.call(null,b))},xsa=new $CLJS.N(null,"value_field","value_field",-980977878),H6=new $CLJS.N(null,"ascending","ascending",-988350486),I6=new $CLJS.N(null,"named","named",-422393479),J6=new $CLJS.N(null,"values_source_config","values_source_config",
-590570309),ysa=new $CLJS.N(null,"field-id","field-id",-353751335),K6=new $CLJS.N(null,"descending","descending",-24766135),L6=new $CLJS.N(null,"binning-strategy","binning-strategy",2063329158),M6=new $CLJS.N(null,"datetime-field","datetime-field",21731696),zsa=new $CLJS.N(null,"use-as-display-name?","use-as-display-name?",686752941),N6=new $CLJS.N(null,"field-literal","field-literal",-1295883554),O6=new $CLJS.N(null,"viz-settings","viz-settings",256055379),Asa=new $CLJS.N(null,"rows","rows",850049680),
Bsa=new $CLJS.N(null,"special-fn","special-fn",1290649344),G6=new $CLJS.N("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),p6=new $CLJS.N(null,"ignore-path","ignore-path",944069061),Csa=new $CLJS.N(null,"label_field","label_field",-1573182765),Dsa=new $CLJS.N(null,"fk-\x3e","fk-\x3e",-499026738),Esa=new $CLJS.N(null,"joined-field","joined-field",-2048778268),nsa=new $CLJS.N(null,"visibility_type","visibility_type",-508434247);var P6,Fsa=$CLJS.cj.g($CLJS.P),Gsa=$CLJS.cj.g($CLJS.P),Hsa=$CLJS.cj.g($CLJS.P),Isa=$CLJS.cj.g($CLJS.P),Jsa=$CLJS.M.j($CLJS.P,$CLJS.Qk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));P6=new $CLJS.vj($CLJS.Ui.h("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Km.h(j6,$CLJS.C),$CLJS.Pj,Jsa,Fsa,Gsa,Hsa,Isa);P6.o(null,$CLJS.NP,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);b=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.NP,b],null);return null!=a?$CLJS.kf.h(b,a):b});
P6.o(null,$CLJS.AG,function(a){$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.AG,a instanceof $CLJS.N?$CLJS.f_(a):a],null)});P6.o(null,L6,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.m(a)?$CLJS.kf.h(P6.g(new $CLJS.S(null,3,5,$CLJS.T,[L6,b,c],null)),a):new $CLJS.S(null,3,5,$CLJS.T,[L6,o6.h?o6.h(b,p6):o6.call(null,b,p6),j6(c)],null)});
P6.o(null,$CLJS.EQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);var c=o6.h?o6.h(a,p6):o6.call(null,a,p6);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.EQ,b,function(){var d=$CLJS.m($CLJS.DG.g(c))?$CLJS.Pm.j(c,$CLJS.DG,$CLJS.Oi):c;d=$CLJS.m($CLJS.QQ.g(c))?$CLJS.Pm.j(d,$CLJS.QQ,$CLJS.Oi):d;return $CLJS.m($CLJS.PR.g(c))?$CLJS.Pm.j(d,$CLJS.PR,function(e){return $CLJS.m($CLJS.XK.g(e))?$CLJS.Pm.j(e,$CLJS.XK,$CLJS.Oi):e}):d}()],null)});
P6.o(null,N6,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[N6,b instanceof $CLJS.N?$CLJS.f_(b):b,$CLJS.Oi.g(a)],null)});P6.o(null,M6,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.m(a)?new $CLJS.S(null,4,5,$CLJS.T,[M6,o6.h?o6.h(b,p6):o6.call(null,b,p6),$CLJS.wu,j6(a)],null):new $CLJS.S(null,3,5,$CLJS.T,[M6,o6.h?o6.h(b,p6):o6.call(null,b,p6),j6(c)],null)});
P6.o(null,$CLJS.rK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null),d=$CLJS.J(a,3,null);a=$CLJS.J(a,4,null);return $CLJS.m(a)?$CLJS.kf.h(P6.g(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.rK,b,c,d],null)),o6.h?o6.h(a,p6):o6.call(null,a,p6)):new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.rK,o6.h?o6.h(b,p6):o6.call(null,b,p6),$CLJS.Ae(c)?c:j6(c),j6(d)],null)});
P6.o(null,$CLJS.sJ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.m(a)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.sJ,b,j6(a)],null):new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.sJ,$CLJS.nB],null)});P6.o(null,$CLJS.jJ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.jJ,b,j6(a)],null)});
P6.o(null,$CLJS.eK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.eK,o6.h?o6.h(b,p6):o6.call(null,b,p6),c,j6(a)],null)});P6.o(null,$CLJS.MK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.MK,o6.h?o6.h(b,p6):o6.call(null,b,p6),c,j6(a)],null)});
P6.o(null,$CLJS.dK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.m(a)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.dK,o6.h?o6.h(b,p6):o6.call(null,b,p6),j6(a)],null):new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dK,o6.h?o6.h(b,p6):o6.call(null,b,p6)],null)});
P6.o(null,$CLJS.pK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.m(a)?new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.pK,o6.h?o6.h(b,p6):o6.call(null,b,p6),j6(c),j6(a)],null):new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.pK,o6.h?o6.h(b,p6):o6.call(null,b,p6),j6(c)],null)});
P6.o(null,$CLJS.iJ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.iJ,o6.h?o6.h(b,p6):o6.call(null,b,p6),o6.h?o6.h(c,p6):o6.call(null,c,p6),j6(a)],null)});P6.o(null,$CLJS.ql,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ql,b,a],null)});
P6.o(null,$CLJS.Pj,function(a){var b=$CLJS.A(a);a=$CLJS.C(b);b=$CLJS.D(b);return $CLJS.oh.j(new $CLJS.S(null,1,5,$CLJS.T,[j6(a)],null),$CLJS.rg.g(function(c){return o6.h?o6.h(c,p6):o6.call(null,c,p6)}),b)});
var Q6=function Q6(a){if($CLJS.m($CLJS.Mm.h($CLJS.pf,$CLJS.Jb)(a))){var c=j6(a);var d=new $CLJS.ni(null,new $CLJS.k(null,18,[$CLJS.qk,null,$CLJS.lm,null,$CLJS.AK,null,$CLJS.fK,null,$CLJS.xu,null,$CLJS.NJ,null,$CLJS.OK,null,$CLJS.QK,null,$CLJS.NI,null,$CLJS.nJ,null,$CLJS.km,null,$CLJS.RI,null,$CLJS.vK,null,$CLJS.ZJ,null,$CLJS.Xl,null,$CLJS.lD,null,$CLJS.DJ,null,$CLJS.tK,null],null),null);c=d.g?d.g(c):d.call(null,c)}else c=null;return $CLJS.m(c)?c:$CLJS.m(m6(a))?(a=$CLJS.C(a),Q6.g?Q6.g(a):Q6.call(null,
a)):null},Ksa=new $CLJS.k(null,8,[$CLJS.Wk,j6,$CLJS.UQ,function(a){a=k6(a);return $CLJS.A($CLJS.iR.g(a))?$CLJS.Pm.j(a,$CLJS.iR,msa):a},$CLJS.vO,new $CLJS.k(null,6,[$CLJS.NP,function R6(a){if($CLJS.m($CLJS.Mm.h($CLJS.pf,$CLJS.Jb)(a)))return j6(a);if($CLJS.m(n6(I6,a))){a=$CLJS.A(a);$CLJS.C(a);var c=$CLJS.D(a);a=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[I6,R6.g?R6.g(a):R6.call(null,a)],null),c)}return $CLJS.m($CLJS.m(m6(a))?Q6($CLJS.Zd(a)):null)?$CLJS.Om.h(R6,a):o6.h?o6.h(a,
p6):o6.call(null,a,p6)},$CLJS.PQ,function(a){return $CLJS.oh.h($CLJS.P,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);u=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.f_(u),o6.h?o6.h(q,p6):o6.call(null,q,p6)],null);l.add(u);n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=
$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.f_(l),o6.h?o6.h(f,p6):o6.call(null,f,p6)],null),d($CLJS.zd(e)))}return null}},null,null)}(a)}())},$CLJS.ZR,function(a){return $CLJS.Mg(function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n);q=$CLJS.m(m6(q))?P6.g(q):$CLJS.lf(P6.g($CLJS.lf(q)));l.add(q);
n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}l=$CLJS.C(e);return $CLJS.nf($CLJS.m(m6(l))?P6.g(l):$CLJS.lf(P6.g($CLJS.lf(l))),d($CLJS.zd(e)))}return null}},null,null)}(a)}())},$CLJS.lR,function(a){a=k6(a);a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.UQ);if($CLJS.m(b))return a=$CLJS.QN(a,new $CLJS.k(null,1,[$CLJS.UQ,$CLJS.vO],null)),b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.UQ],null),a=o6.h?o6.h(a,b):o6.call(null,a,b),$CLJS.QN(a,new $CLJS.k(null,1,[$CLJS.vO,$CLJS.UQ],
null));b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.vO],null);return o6.h?o6.h(a,b):o6.call(null,a,b)},$CLJS.OP,new $CLJS.k(null,1,[G6,q6],null),$CLJS.VO,new $CLJS.k(null,1,[G6,function(a){a=o6.h?o6.h(a,$CLJS.vO):o6.call(null,a,$CLJS.vO);var b=$CLJS.Qf(a),c=$CLJS.M.h(b,$CLJS.XK),d=$CLJS.M.h(b,$CLJS.xO);a=$CLJS.M.h(b,$CLJS.wP);b=$CLJS.m(c)?$CLJS.Pm.j(b,$CLJS.XK,j6):b;d=$CLJS.m($CLJS.Mm.h($CLJS.pf,$CLJS.Jb)(d))?$CLJS.Pm.j(b,$CLJS.xO,j6):b;return $CLJS.m(a)?$CLJS.Pm.j(d,$CLJS.wP,$CLJS.f_):d}],null)],null),
$CLJS.$D,new $CLJS.k(null,1,[$CLJS.oP,$CLJS.Ye],null),$CLJS.OO,new $CLJS.k(null,1,[G6,function(a){var b=$CLJS.Qf(a),c=$CLJS.M.h(b,$CLJS.Wk),d=$CLJS.M.h(b,$CLJS.aL),e=$CLJS.M.h(b,$CLJS.Gk);a=$CLJS.M.h(b,J6);b=$CLJS.m(e)?$CLJS.Pm.j(b,$CLJS.Gk,$CLJS.f_):b;c=$CLJS.m(c)?$CLJS.Pm.j(b,$CLJS.Wk,j6):b;d=$CLJS.m(d)?$CLJS.Pm.j(c,$CLJS.aL,function(f){return o6.h?o6.h(f,p6):o6.call(null,f,p6)}):c;d=$CLJS.m(a)?$CLJS.q_.j(d,new $CLJS.S(null,2,5,$CLJS.T,[J6,Csa],null),function(f){return o6.h?o6.h(f,p6):o6.call(null,
f,p6)}):d;return $CLJS.m(a)?$CLJS.q_.j(d,new $CLJS.S(null,2,5,$CLJS.T,[J6,xsa],null),function(f){return o6.h?o6.h(f,p6):o6.call(null,f,p6)}):d}],null),$CLJS.MO,function(a){return null==a?null:j6(a)},$CLJS.OP,new $CLJS.k(null,1,[G6,q6],null),O6,j6],null),o6=function o6(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return o6.l(arguments[0],1<c.length?new $CLJS.z(c.slice(1),0,null):null)};
o6.l=function(a,b){b=$CLJS.J(b,0,null);var c=b instanceof $CLJS.N?new $CLJS.S(null,1,5,$CLJS.T,[b],null):$CLJS.Mg(b);b=$CLJS.A(c)?$CLJS.Ju(Ksa,c):null;try{return $CLJS.de(b)?b.g?b.g(a):b.call(null,a):$CLJS.pe(a)?a:$CLJS.oe(a)?$CLJS.oh.h($CLJS.P,function(){return function g(f){return new $CLJS.yf(null,function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.re(l)){var n=$CLJS.$c(l),q=$CLJS.E(n),u=$CLJS.Bf(q);a:for(var v=0;;)if(v<q){var x=$CLJS.be(n,v),y=$CLJS.J(x,0,null);x=$CLJS.J(x,1,null);y=j6(y);y=new $CLJS.S(null,
2,5,$CLJS.T,[y,o6.l(x,$CLJS.G([$CLJS.kf.h($CLJS.Mg(c),y)]))],null);u.add(y);v+=1}else{n=!0;break a}return n?$CLJS.Ef($CLJS.Gf(u),g($CLJS.ad(l))):$CLJS.Ef($CLJS.Gf(u),null)}n=$CLJS.C(l);u=$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);u=j6(u);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[u,o6.l(n,$CLJS.G([$CLJS.kf.h($CLJS.Mg(c),u)]))],null),g($CLJS.zd(l)))}return null}},null,null)}(a)}()):$CLJS.m(m6(a))?P6.g(a):$CLJS.ne(a)?$CLJS.Om.h(function(e){return o6.l(e,$CLJS.G([$CLJS.kf.h($CLJS.Mg(c),G6)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.yj($CLJS.VH("Error normalizing form: {0}",$CLJS.G([$CLJS.TZ(d)])),new $CLJS.k(null,3,[$CLJS.rF,a,$CLJS.Zl,c,Bsa,b],null),d);}throw e;}};o6.v=1;o6.B=function(a){var b=$CLJS.C(a);a=$CLJS.D(a);return this.l(b,a)};
var s6=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j($CLJS.P,$CLJS.Qk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.m(m6(f))?$CLJS.C(f):null},$CLJS.Pj,e,a,b,c,d)}();s6.o(null,$CLJS.Pj,function(a){return a});
s6.o(null,$CLJS.EQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);if($CLJS.m(n6($CLJS.EQ,b))){$CLJS.J(b,0,null);var c=$CLJS.J(b,1,null);b=$CLJS.J(b,2,null);return s6.g(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.EQ,c,$CLJS.Rf($CLJS.en.l($CLJS.G([b,a])))],null))}return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.EQ,b,$CLJS.Rf(a)],null)});
s6.o(null,$CLJS.NP,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.je(a)?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.NP,b],null):new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.NP,b,a],null)});s6.o(null,ysa,function(a){$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.m(m6(a))?s6.g(a):new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.EQ,a,null],null)});
s6.o(null,N6,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.EQ,b,new $CLJS.k(null,1,[$CLJS.DG,a],null)],null)});s6.o(null,Dsa,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);var c=t6(b);$CLJS.J(c,0,null);b=$CLJS.J(c,1,null);$CLJS.J(c,2,null);c=t6(a);$CLJS.J(c,0,null);a=$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.EQ,a,$CLJS.U.j(c,$CLJS.WO,b)],null)});
s6.o(null,Esa,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=t6(a);return $CLJS.R.D($CLJS.u_,a,$CLJS.U,$CLJS.G([$CLJS.mQ,b]))});
s6.o(null,M6,function(a){var b=$CLJS.E(a);switch(b){case 3:$CLJS.J(a,0,null);b=$CLJS.J(a,1,null);var c=$CLJS.J(a,2,null);a=t6(b);b=c;$CLJS.J(a,0,null);$CLJS.J(a,1,null);c=$CLJS.J(a,2,null);c=$CLJS.Qf(c);c=$CLJS.M.h(c,$CLJS.DG);return $CLJS.Gb(c)||$CLJS.HS.h(c,b)?$CLJS.R.D($CLJS.u_,a,$CLJS.U,$CLJS.G([$CLJS.QQ,b])):a;case 4:return $CLJS.J(a,0,null),b=$CLJS.J(a,1,null),$CLJS.J(a,2,null),c=$CLJS.J(a,3,null),s6.g(new $CLJS.S(null,3,5,$CLJS.T,[M6,b,c],null));default:throw Error(["No matching clause: ",
$CLJS.p.g(b)].join(""));}});s6.o(null,L6,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null),d=$CLJS.J(a,3,null);a=$CLJS.J(a,4,null);var e=t6(b);$CLJS.J(e,0,null);b=$CLJS.J(e,1,null);e=$CLJS.J(e,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.EQ,b,$CLJS.U.j(e,$CLJS.PR,$CLJS.en.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.XK,c],null),$CLJS.m(d)?$CLJS.Pf([c,d]):null,a])))],null)});
for(var S6=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,$CLJS.Bk,$CLJS.Tk],null)),T6=null,U6=0,V6=0;;)if(V6<U6){var W6=T6.$(null,V6);s6.o(null,W6,function(){return function(a){return u6(a)}}(S6,T6,U6,V6,W6));V6+=1}else{var X6=$CLJS.A(S6);if(X6){var Y6=X6;if($CLJS.re(Y6)){var Z6=$CLJS.$c(Y6),Lsa=$CLJS.ad(Y6),Msa=Z6,Nsa=$CLJS.E(Z6);S6=Lsa;T6=Msa;U6=Nsa}else{var $6=$CLJS.C(Y6);s6.o(null,$6,function(){return function(a){return u6(a)}}(S6,T6,U6,V6,$6,Y6,X6));S6=$CLJS.D(Y6);T6=null;U6=0}V6=0}else break}
s6.o(null,$CLJS.VI,function(a){a=$CLJS.A(a);$CLJS.C(a);var b=$CLJS.D(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.oh.h(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.VI,t6(a),t6(b)],null),c)});s6.o(null,$CLJS.rK,function(a){a=$CLJS.A(a);$CLJS.C(a);var b=$CLJS.D(a);a=$CLJS.C(b);b=$CLJS.D(b);var c=t6(a);a=$CLJS.m($CLJS.g_($CLJS.EQ,a))?$CLJS.u_.l(c,$CLJS.Cm,$CLJS.G([$CLJS.QQ])):c;return $CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rK,a],null),b)});
for(var a7=$CLJS.A(new $CLJS.S(null,15,5,$CLJS.T,[$CLJS.vJ,$CLJS.nK,$CLJS.LJ,$CLJS.wJ,$CLJS.fm,$CLJS.DK,$CLJS.gm,$CLJS.sm,$CLJS.im,$CLJS.rm,$CLJS.LI,$CLJS.aJ,$CLJS.yI,$CLJS.oJ,$CLJS.KI],null)),b7=null,c7=0,d7=0;;)if(d7<c7){var e7=b7.$(null,d7);s6.o(null,e7,function(){return function(a){return v6(a)}}(a7,b7,c7,d7,e7));d7+=1}else{var f7=$CLJS.A(a7);if(f7){var g7=f7;if($CLJS.re(g7)){var h7=$CLJS.$c(g7),Osa=$CLJS.ad(g7),Psa=h7,Qsa=$CLJS.E(h7);a7=Osa;b7=Psa;c7=Qsa}else{var i7=$CLJS.C(g7);s6.o(null,i7,
function(){return function(a){return v6(a)}}(a7,b7,c7,d7,i7,g7,f7));a7=$CLJS.D(g7);b7=null;c7=0}d7=0}else break}s6.o(null,Asa,function(){return null});s6.o(null,$CLJS.oO,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.oO,s6.g(b),a],null)});
s6.o(null,I6,function(a){a=$CLJS.A(a);$CLJS.C(a);a=$CLJS.D(a);var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);var d=$CLJS.D(c);c=s6.g;var e=$CLJS.T;b=s6.g(b);d=$CLJS.J(d,0,null);d=$CLJS.Qf(d);a=!1===$CLJS.M.h(d,zsa)?new $CLJS.k(null,1,[$CLJS.V,a],null):new $CLJS.k(null,1,[$CLJS.UI,a],null);return c.call(s6,new $CLJS.S(null,3,5,e,[$CLJS.oO,b,a],null))});
for(var j7=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lD,$CLJS.NJ],null)),k7=null,l7=0,m7=0;;)if(m7<l7){var n7=k7.$(null,m7);s6.o(null,n7,function(){return function(a){return w6(a)}}(j7,k7,l7,m7,n7));m7+=1}else{var o7=$CLJS.A(j7);if(o7){var p7=o7;if($CLJS.re(p7)){var q7=$CLJS.$c(p7),Rsa=$CLJS.ad(p7),Ssa=q7,Tsa=$CLJS.E(q7);j7=Rsa;k7=Ssa;l7=Tsa}else{var r7=$CLJS.C(p7);s6.o(null,r7,function(){return function(a){return w6(a)}}(j7,k7,l7,m7,r7,p7,o7));j7=$CLJS.D(p7);k7=null;l7=0}m7=0}else break}
for(var s7=$CLJS.A(new $CLJS.S(null,9,5,$CLJS.T,[$CLJS.DJ,$CLJS.RK,$CLJS.NI,$CLJS.AK,$CLJS.tK,$CLJS.qk,$CLJS.Xl,$CLJS.vK,$CLJS.RI],null)),t7=null,u7=0,v7=0;;)if(v7<u7){var w7=t7.$(null,v7);s6.o(null,w7,function(){return function(a){return x6(a)}}(s7,t7,u7,v7,w7));v7+=1}else{var x7=$CLJS.A(s7);if(x7){var y7=x7;if($CLJS.re(y7)){var z7=$CLJS.$c(y7),Usa=$CLJS.ad(y7),Vsa=z7,Wsa=$CLJS.E(z7);s7=Usa;t7=Vsa;u7=Wsa}else{var A7=$CLJS.C(y7);s6.o(null,A7,function(){return function(a){return x6(a)}}(s7,t7,u7,v7,
A7,y7,x7));s7=$CLJS.D(y7);t7=null;u7=0}v7=0}else break}s6.o(null,$CLJS.nJ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nJ,t6(b),a],null)});
for(var B7=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ZJ,$CLJS.fK],null)),C7=null,D7=0,E7=0;;)if(E7<D7){var F7=C7.$(null,E7);s6.o(null,F7,function(){return function(a){return y6(a)}}(B7,C7,D7,E7,F7));E7+=1}else{var G7=$CLJS.A(B7);if(G7){var H7=G7;if($CLJS.re(H7)){var I7=$CLJS.$c(H7),Xsa=$CLJS.ad(H7),Ysa=I7,Zsa=$CLJS.E(I7);B7=Xsa;C7=Ysa;D7=Zsa}else{var J7=$CLJS.C(H7);s6.o(null,J7,function(){return function(a){return y6(a)}}(B7,C7,D7,E7,J7,H7,G7));B7=$CLJS.D(H7);C7=null;D7=0}E7=0}else break}
s6.o(null,$CLJS.OK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.OK,s6.g(b),s6.g(a)],null)});
s6.o(null,$CLJS.$I,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.m(a)?$CLJS.kf.h(s6.g(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$I,b],null)),o6.l(a,$CLJS.G([p6]))):new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$I,$CLJS.Mg(function(){return function e(d){return new $CLJS.yf(null,function(){for(;;){var f=$CLJS.A(d);if(f){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Bf(l);a:for(var q=0;;)if(q<l){var u=$CLJS.be(g,q),v=$CLJS.J(u,0,null);u=$CLJS.J(u,1,null);v=new $CLJS.S(null,
2,5,$CLJS.T,[s6.g(v),s6.g(u)],null);n.add(v);q+=1}else{g=!0;break a}return g?$CLJS.Ef($CLJS.Gf(n),e($CLJS.ad(f))):$CLJS.Ef($CLJS.Gf(n),null)}g=$CLJS.C(f);n=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[s6.g(n),s6.g(g)],null),e($CLJS.zd(f)))}return null}},null,null)}(b)}())],null)});
s6.o(null,$CLJS.bI,function(a){a=$CLJS.A(a);$CLJS.C(a);var b=$CLJS.D(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.oh.h(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.bI,s6.g(a),$CLJS.F.h(0,b)?1:s6.g(b)],null),$CLJS.rg.h(s6,c))});
var A6=function A6(a){return function f(d,e){try{if($CLJS.m(function(){var x=l6($CLJS.we);return x.g?x.g(e):x.call(null,e)}()))return f(d,$CLJS.Mg(e));throw $CLJS.Z;}catch(x){if(x instanceof Error)if(d=x,d===$CLJS.Z)try{if($CLJS.m(function(){var y=l6($CLJS.pf);return y.g?y.g(e):y.call(null,e)}()))return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,1,5,$CLJS.T,[e],null)],null);throw $CLJS.Z;}catch(y){if(y instanceof Error)if(d=y,d===$CLJS.Z)try{if($CLJS.qe(e)&&2<=$CLJS.E(e))try{var g=$CLJS.Um.j(e,
0,2);if($CLJS.qe(g)&&2===$CLJS.E(g))try{var l=$CLJS.Td(g,0);if($CLJS.m($CLJS.Lm.h($CLJS.pf,$CLJS.$f(new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.lm,null,$CLJS.xu,null,$CLJS.QK,null,$CLJS.km,null,I6,null],null),null)))(l)))try{var n=$CLJS.Td(g,1);if($CLJS.m(Q6(n)))return $CLJS.Mg($CLJS.Ve($CLJS.qg,$CLJS.rg.h(A6,a)));throw $CLJS.Z;}catch(B){if(B instanceof Error){var q=B;if(q===$CLJS.Z)throw $CLJS.Z;throw q;}throw B;}else throw $CLJS.Z;}catch(B){if(B instanceof Error){q=B;if(q===$CLJS.Z)throw $CLJS.Z;
throw q;}throw B;}else throw $CLJS.Z;}catch(B){if(B instanceof Error){q=B;if(q===$CLJS.Z)throw $CLJS.Z;throw q;}throw B;}else throw $CLJS.Z;}catch(B){if(B instanceof Error)if(q=B,q===$CLJS.Z)try{if($CLJS.qe(e)&&1<=$CLJS.E(e))try{var u=$CLJS.Um.j(e,0,1);if($CLJS.qe(u)&&1===$CLJS.E(u))try{if($CLJS.Td(u,0)instanceof $CLJS.N)return new $CLJS.S(null,1,5,$CLJS.T,[e],null);throw $CLJS.Z;}catch(H){if(H instanceof Error){var v=H;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof
Error){v=H;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){v=H;if(v===$CLJS.Z)return e;throw v;}throw H;}else throw q;else throw B;}else throw d;else throw y;}else throw d;else throw x;}}($CLJS.Lg,a)},K7=function K7(a){return function f(d,e){try{var g=l6($CLJS.we);var l=g.g?g.g(e):g.call(null,e);if($CLJS.m(l))return f(d,$CLJS.Mg(e));throw $CLJS.Z;}catch(B){if(B instanceof Error)if(l=B,l===$CLJS.Z)try{if($CLJS.qe(e)&&2===$CLJS.E(e))try{var n=$CLJS.Td(e,
1);if($CLJS.O(n,$CLJS.GJ)){var q=$CLJS.Td(e,0);return f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.GJ,q],null))}throw $CLJS.Z;}catch(H){if(H instanceof Error)if(l=H,l===$CLJS.Z)try{n=$CLJS.Td(e,1);if($CLJS.O(n,$CLJS.tJ))return q=$CLJS.Td(e,0),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tJ,q],null));throw $CLJS.Z;}catch(I){if(I instanceof Error){var u=I;if(u===$CLJS.Z)try{n=$CLJS.Td(e,1);if($CLJS.O(n,H6))return q=$CLJS.Td(e,0),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.GJ,q],null));throw $CLJS.Z;}catch(Q){if(Q instanceof
Error)if(l=Q,l===$CLJS.Z)try{n=$CLJS.Td(e,1);if($CLJS.O(n,K6))return q=$CLJS.Td(e,0),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tJ,q],null));throw $CLJS.Z;}catch(Y){if(Y instanceof Error)if(n=Y,n===$CLJS.Z)try{var v=$CLJS.Td(e,0);if($CLJS.O(v,H6))return q=$CLJS.Td(e,1),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.GJ,q],null));throw $CLJS.Z;}catch(aa){if(aa instanceof Error)if(n=aa,n===$CLJS.Z)try{v=$CLJS.Td(e,0);if($CLJS.O(v,K6))return q=$CLJS.Td(e,1),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tJ,q],null));
throw $CLJS.Z;}catch(ha){if(ha instanceof Error)if(n=ha,n===$CLJS.Z)try{v=$CLJS.Td(e,0);if($CLJS.O(v,$CLJS.GJ))return q=$CLJS.Td(e,1),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.GJ,t6(q)],null);throw $CLJS.Z;}catch(qa){if(qa instanceof Error)if(n=qa,n===$CLJS.Z)try{v=$CLJS.Td(e,0);if($CLJS.O(v,$CLJS.tJ))return q=$CLJS.Td(e,1),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tJ,t6(q)],null);throw $CLJS.Z;}catch(Ea){if(Ea instanceof Error){q=Ea;if(q===$CLJS.Z)throw $CLJS.Z;throw q;}throw Ea;}else throw n;else throw qa;
}else throw n;else throw ha;}else throw n;else throw aa;}else throw n;else throw Y;}else throw l;else throw Q;}else throw u;}else throw I;}else throw l;else throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error)if(l=H,l===$CLJS.Z)try{if($CLJS.qe(e)&&0<=$CLJS.E(e))try{var x=$CLJS.Um.j(e,0,0);if($CLJS.qe(x)&&0===$CLJS.E(x))try{var y=$CLJS.Um.h(e,0);if($CLJS.F.h(y,a))return $CLJS.Mg($CLJS.gn.g($CLJS.rg.h(K7,a)));throw $CLJS.Z;}catch(I){if(I instanceof Error){u=I;if(u===$CLJS.Z)throw $CLJS.Z;throw u;
}throw I;}else throw $CLJS.Z;}catch(I){if(I instanceof Error){u=I;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw I;}else throw $CLJS.Z;}catch(I){if(I instanceof Error){u=I;if(u===$CLJS.Z)return $CLJS.e_(f,d,e);throw u;}throw I;}else throw l;else throw H;}else throw l;else throw B;}}($CLJS.Lg,a)},D6=$CLJS.Km.h(i6,function(a){var b=E6($CLJS.NP.g(a))?$CLJS.Pm.j(a,$CLJS.NP,osa):a;b=E6($CLJS.GP.g(a))?$CLJS.Pm.j(b,$CLJS.GP,psa):b;b=E6($CLJS.xO.g(a))?$CLJS.Pm.j(b,$CLJS.xO,$CLJS.bg($CLJS.Om,r6)):b;b=E6($CLJS.ZR.g(a))?
$CLJS.Pm.j(b,$CLJS.ZR,K7):b;return E6($CLJS.lR.g(a))?$CLJS.Pm.j(b,$CLJS.lR,rsa):b}),$sa=new $CLJS.k(null,3,[$CLJS.UQ,$CLJS.Ye,$CLJS.vO,new $CLJS.k(null,2,[$CLJS.lR,function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.UQ);if($CLJS.m(b))return a=$CLJS.QN(a,new $CLJS.k(null,1,[$CLJS.UQ,$CLJS.vO],null)),b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.UQ],null),a=F6.h?F6.h(a,b):F6.call(null,a,b),$CLJS.QN(a,new $CLJS.k(null,1,[$CLJS.vO,$CLJS.UQ],null));b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.vO],null);return F6.h?F6.h(a,
b):F6.call(null,a,b)},$CLJS.VO,new $CLJS.k(null,1,[G6,function(a){var b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.vO],null);return F6.h?F6.h(a,b):F6.call(null,a,b)}],null)],null),O6,$CLJS.Ye],null),F6=function F6(a){switch(arguments.length){case 1:return F6.g(arguments[0]);case 2:return F6.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};F6.g=function(a){return F6.h(a,$CLJS.Lg)};
F6.h=function(a,b){try{var c=$CLJS.A(b)?$CLJS.Ju($sa,b):null;return $CLJS.de(c)?c.g?c.g(a):c.call(null,a):$CLJS.pe(a)?a:$CLJS.oe(a)?vsa(a,b):$CLJS.ne(a)?wsa(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.yj("Error removing empty clauses from form.",new $CLJS.k(null,2,[$CLJS.rF,a,$CLJS.Zl,b],null),d);throw d;}};F6.v=2;
$CLJS.L7=function(){var a=$CLJS.Km.l(F6,usa,ssa,$CLJS.G([o6]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.yj($CLJS.VH("Error normalizing query: {0}",$CLJS.G([$CLJS.TZ(c)])),new $CLJS.k(null,1,[$CLJS.vO,b],null),c);}throw d;}}}();