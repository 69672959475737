var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var i4,j4,k4,l4;i4=function(a,b,c){var d=$CLJS.W0(a,b);d=$CLJS.Qf(d);d=$CLJS.M.h(d,$CLJS.NP);d=$CLJS.wW($CLJS.Km.j($CLJS.pi([c]),$CLJS.IG,$CLJS.Zd),d);if(!$CLJS.m(d))throw $CLJS.zj($CLJS.VH("No aggregation with uuid {0}",$CLJS.G([c])),new $CLJS.k(null,3,[$CLJS.Ut,c,$CLJS.vO,a,$CLJS.p1,b],null));return d};j4=new $CLJS.N("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);
k4=new $CLJS.N("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);l4=new $CLJS.N("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);$CLJS.Mqa=new $CLJS.N(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.A1.o(null,$CLJS.NP,function(a,b){var c=$CLJS.Rf($CLJS.NP.g($CLJS.W0(a,b)));return $CLJS.m(c)?$CLJS.d2($CLJS.WH("and"),function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);a:for(var u=0;;)if(u<n){var v=$CLJS.be(l,u);v=$CLJS.b1.D(a,b,v,$CLJS.c1);q.add(v);u+=1}else{l=!0;break a}return l?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}q=$CLJS.C(g);return $CLJS.nf($CLJS.b1.D(a,
b,q,$CLJS.c1),f($CLJS.zd(g)))}return null}},null,null)}(c)}()):null});$CLJS.$0.o(null,$CLJS.NP,function(a,b,c){$CLJS.J(c,0,null);var d=$CLJS.J(c,1,null),e=$CLJS.Qf(d);d=$CLJS.M.h(e,$CLJS.DG);e=$CLJS.M.h(e,$CLJS.lk);c=$CLJS.J(c,2,null);c=i4(a,b,c);return $CLJS.en.l($CLJS.G([$CLJS.a1(a,b,c),new $CLJS.k(null,2,[$CLJS.h0,$CLJS.H0,$CLJS.F1,$CLJS.IG.g($CLJS.Zd(c))],null),$CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.DG,d],null):null,$CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.lk,e],null):null]))});
$CLJS.x1.o(null,$CLJS.NP,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);return $CLJS.b1.D(a,b,i4(a,b,c),d)});$CLJS.mV(k4,l4);
for(var m4=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lD,$CLJS.NJ],null)),n4=null,o4=0,p4=0;;)if(p4<o4){var Nqa=n4.$(null,p4);$CLJS.mV(Nqa,k4);p4+=1}else{var q4=$CLJS.A(m4);if(q4){var r4=q4;if($CLJS.re(r4)){var s4=$CLJS.$c(r4),Oqa=$CLJS.ad(r4),Pqa=s4,Qqa=$CLJS.E(s4);m4=Oqa;n4=Pqa;o4=Qqa}else{var Rqa=$CLJS.C(r4);$CLJS.mV(Rqa,k4);m4=$CLJS.D(r4);n4=null;o4=0}p4=0}else break}
$CLJS.x1.o(null,k4,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);if($CLJS.m(c))switch(a=$CLJS.b1.D(a,b,c,d),e=e instanceof $CLJS.N?e.S:null,e){case "count":return $CLJS.VH("Count of {0}",$CLJS.G([a]));case "cum-count":return $CLJS.VH("Cumulative count of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}else switch(e=e instanceof $CLJS.N?e.S:null,e){case "count":return $CLJS.WH("Count");case "cum-count":return $CLJS.WH("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}});$CLJS.y1.o(null,k4,function(a,b,c){a=$CLJS.J(c,0,null);a=a instanceof $CLJS.N?a.S:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));}});$CLJS.$0.o(null,k4,function(a,b,c){var d=$CLJS.U.j,e=$CLJS.tj($CLJS.$0,l4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.U,a,$CLJS.Jl,$CLJS.TI)});$CLJS.x1.o(null,$CLJS.$I,function(){return $CLJS.WH("Case")});
$CLJS.y1.o(null,$CLJS.$I,function(){return"case"});$CLJS.mV(j4,l4);for(var t4=$CLJS.A(new $CLJS.S(null,9,5,$CLJS.T,[$CLJS.DJ,$CLJS.RK,$CLJS.NI,$CLJS.Xl,$CLJS.vK,$CLJS.qk,$CLJS.AK,$CLJS.tK,$CLJS.RI],null)),u4=null,v4=0,w4=0;;)if(w4<v4){var Sqa=u4.$(null,w4);$CLJS.mV(Sqa,j4);w4+=1}else{var x4=$CLJS.A(t4);if(x4){var y4=x4;if($CLJS.re(y4)){var z4=$CLJS.$c(y4),Tqa=$CLJS.ad(y4),Uqa=z4,Vqa=$CLJS.E(z4);t4=Tqa;u4=Uqa;v4=Vqa}else{var Wqa=$CLJS.C(y4);$CLJS.mV(Wqa,j4);t4=$CLJS.D(y4);u4=null;v4=0}w4=0}else break}
$CLJS.y1.o(null,j4,function(a,b,c){a=$CLJS.J(c,0,null);$CLJS.J(c,1,null);$CLJS.J(c,2,null);c=a instanceof $CLJS.N?a.S:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.g(c)].join(""));}});
$CLJS.x1.o(null,j4,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);a=$CLJS.b1.D(a,b,c,d);e=e instanceof $CLJS.N?e.S:null;switch(e){case "avg":return $CLJS.VH("Average of {0}",$CLJS.G([a]));case "cum-sum":return $CLJS.VH("Cumulative sum of {0}",$CLJS.G([a]));case "distinct":return $CLJS.VH("Distinct values of {0}",$CLJS.G([a]));case "max":return $CLJS.VH("Max of {0}",$CLJS.G([a]));case "median":return $CLJS.VH("Median of {0}",$CLJS.G([a]));case "min":return $CLJS.VH("Min of {0}",
$CLJS.G([a]));case "stddev":return $CLJS.VH("Standard deviation of {0}",$CLJS.G([a]));case "sum":return $CLJS.VH("Sum of {0}",$CLJS.G([a]));case "var":return $CLJS.VH("Variance of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}});$CLJS.x1.o(null,$CLJS.nJ,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null);c=$CLJS.J(c,3,null);return $CLJS.VH("{0}th percentile of {1}",$CLJS.G([c,$CLJS.b1.D(a,b,e,d)]))});$CLJS.y1.o(null,$CLJS.nJ,function(){return"percentile"});
$CLJS.mV($CLJS.nJ,l4);$CLJS.x1.o(null,$CLJS.OK,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null);$CLJS.J(c,3,null);return $CLJS.VH("Sum of {0} matching condition",$CLJS.G([$CLJS.b1.D(a,b,e,d)]))});$CLJS.y1.o(null,$CLJS.OK,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var d=$CLJS.J(c,2,null);$CLJS.J(c,3,null);return["sum_where_",$CLJS.p.g($CLJS.z1.j(a,b,d))].join("")});$CLJS.mV($CLJS.OK,l4);$CLJS.x1.o(null,$CLJS.ZJ,function(){return $CLJS.WH("Share of rows matching condition")});
$CLJS.y1.o(null,$CLJS.ZJ,function(){return"share"});$CLJS.mV($CLJS.ZJ,l4);$CLJS.x1.o(null,$CLJS.fK,function(){return $CLJS.WH("Count of rows matching condition")});$CLJS.y1.o(null,$CLJS.fK,function(){return"count-where"});$CLJS.mV($CLJS.fK,l4);
$CLJS.$0.o(null,l4,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var d=$CLJS.J(c,2,null),e=$CLJS.en.l;d=$CLJS.m(d)?$CLJS.li($CLJS.a1(a,b,d),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.mR],null)):null;var f=$CLJS.tj($CLJS.$0,$CLJS.Pj);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.en,$CLJS.G([d,a]))});$CLJS.cW.o(null,$CLJS.NP,function(a){return a});$CLJS.x1.o(null,$CLJS.oW,function(a,b,c){a=$CLJS.Qf(c);a=$CLJS.M.h(a,$CLJS.rW);return $CLJS.UI.g(a.A?a.A():a.call(null))});
$CLJS.D1.o(null,$CLJS.oW,function(a,b,c){var d=$CLJS.Qf(c);a=$CLJS.M.h(d,$CLJS.BV);b=$CLJS.M.h(d,$CLJS.rW);c=$CLJS.M.h(d,$CLJS.pW);d=$CLJS.M.h(d,$CLJS.C0);a=$CLJS.U.l(b.A?b.A():b.call(null),$CLJS.v1,$CLJS.jG(a),$CLJS.G([$CLJS.u1,c]));return null!=d?$CLJS.U.j(a,$CLJS.j1,d):a});