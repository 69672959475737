var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var qL,wga,uL,zL;
$CLJS.oL=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return $CLJS.Hu([$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-",$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-4",$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-",$CLJS.p.g(b),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-",$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),
$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a())].join(""))};$CLJS.pL=function(a){if("string"===typeof a){var b=$CLJS.Di(/[+-]?\d+/,a);return $CLJS.m(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.bo(a));};qL=function(a){return $CLJS.PH(a,$CLJS.cl)||$CLJS.PH(a,$CLJS.Qj)};
$CLJS.rL=new $CLJS.N("metabase.lib.schema.common","int-greater-than-zero","metabase.lib.schema.common/int-greater-than-zero",1130338092);wga=new $CLJS.N("metabase.lib.schema.common","external-op","metabase.lib.schema.common/external-op",1918684841);$CLJS.sL=new $CLJS.N("lib","external-op","lib/external-op",-1470923877);$CLJS.tL=new $CLJS.N("metabase.lib.schema.common","non-blank-string","metabase.lib.schema.common/non-blank-string",-2032366619);
uL=new $CLJS.N("metabase.lib.schema.common","semantic-type","metabase.lib.schema.common/semantic-type",-1623124128);$CLJS.vL=new $CLJS.N(null,"database-type","database-type",-426840562);$CLJS.wL=new $CLJS.N("metabase.lib.schema.common","int-greater-than-or-equal-to-zero","metabase.lib.schema.common/int-greater-than-or-equal-to-zero",-1646435681);$CLJS.xL=new $CLJS.N("metabase.lib.schema.common","options","metabase.lib.schema.common/options",-1078359294);
$CLJS.yL=new $CLJS.N("metabase.lib.schema.common","base-type","metabase.lib.schema.common/base-type",999524206);zL=new $CLJS.N("metabase.lib.schema.common","uuid","metabase.lib.schema.common/uuid",1172938098);$CLJS.kL($CLJS.tL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Nl,new $CLJS.k(null,1,[$CLJS.qk,1],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.dv,"non-blank string"],null),$CLJS.$f($CLJS.PF)],null)],null));$CLJS.kL($CLJS.wL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rt,new $CLJS.k(null,1,[$CLJS.qk,0],null)],null));$CLJS.kL($CLJS.rL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rt,new $CLJS.k(null,1,[$CLJS.qk,1],null)],null));
$CLJS.kL(zL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Nl,new $CLJS.k(null,2,[$CLJS.qk,36,$CLJS.Xl,36],null)],null));$CLJS.kL(uL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,2,[$CLJS.dv,"valid semantic type",$CLJS.fv,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.ql);return["Not a valid semantic type: ",$CLJS.aj.l($CLJS.G([a]))].join("")}],null),qL],null));
$CLJS.kL($CLJS.yL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,2,[$CLJS.dv,"valid base type",$CLJS.fv,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.ql);return["Not a valid base type: ",$CLJS.aj.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.PH(a,$CLJS.nl)&&!qL(a)}],null));
$CLJS.kL($CLJS.xL,new $CLJS.S(null,8,5,$CLJS.T,[$CLJS.Wl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.IG,zL],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DG,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Jt,$CLJS.yL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.lk,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Jt,$CLJS.yL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Jl,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Jt,
uL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.vL,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Jt,$CLJS.tL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.V,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Jt,$CLJS.tL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.UI,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Jt,$CLJS.tL],null)],null)],null));
$CLJS.kL(wga,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Wl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vz,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,$CLJS.sL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.QJ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bk,$CLJS.Nl,$CLJS.Ek],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Nk,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.xL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ml,$CLJS.Vt],null)],null)],null));