var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var p2,q2,s2,t2;$CLJS.n2=function(a){if("number"===typeof a)return a;switch(a instanceof $CLJS.N?a.S:null){case "current":return 0;case "next":return 1;case "last":return-1;default:return 0}};
$CLJS.o2=function(a,b){a=$CLJS.n2(a);b=$CLJS.m(b)?b:$CLJS.xz;return 0===a?$CLJS.F.h(b,$CLJS.xz)?$CLJS.WH("Today"):$CLJS.VH("This {0}",$CLJS.G([$CLJS.G1.g(b)])):$CLJS.F.h(a,1)?$CLJS.F.h(b,$CLJS.xz)?$CLJS.WH("Tomorrow"):$CLJS.VH("Next {0}",$CLJS.G([$CLJS.G1.g(b)])):$CLJS.F.h(a,-1)?$CLJS.F.h(b,$CLJS.xz)?$CLJS.WH("Yesterday"):$CLJS.VH("Previous {0}",$CLJS.G([$CLJS.G1.g(b)])):0>a?$CLJS.VH("Previous {0} {1}",$CLJS.G([Math.abs(a),$CLJS.G1.h(Math.abs(a),b)])):0<a?$CLJS.VH("Next {0} {1}",$CLJS.G([a,$CLJS.G1.h(a,
b)])):null};p2=new $CLJS.N("metabase.lib.filter","unary","metabase.lib.filter/unary",-336199667);q2=new $CLJS.N("metabase.lib.filter","varargs","metabase.lib.filter/varargs",1417033079);$CLJS.r2=new $CLJS.N(null,"operators","operators",-2064102509);s2=new $CLJS.N("metabase.lib.filter","compound","metabase.lib.filter/compound",1924103780);t2=new $CLJS.N("metabase.lib.filter","binary","metabase.lib.filter/binary",-1921360491);for(var u2=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Lj,$CLJS.Bk],null)),v2=null,w2=0,x2=0;;)if(x2<w2){var lpa=v2.$(null,x2);$CLJS.mV(lpa,s2);x2+=1}else{var y2=$CLJS.A(u2);if(y2){var z2=y2;if($CLJS.re(z2)){var A2=$CLJS.$c(z2),mpa=$CLJS.ad(z2),npa=A2,opa=$CLJS.E(A2);u2=mpa;v2=npa;w2=opa}else{var ppa=$CLJS.C(z2);$CLJS.mV(ppa,s2);u2=$CLJS.D(z2);v2=null;w2=0}x2=0}else break}
for(var B2=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,$CLJS.DK],null)),C2=null,D2=0,E2=0;;)if(E2<D2){var qpa=C2.$(null,E2);$CLJS.mV(qpa,q2);E2+=1}else{var F2=$CLJS.A(B2);if(F2){var G2=F2;if($CLJS.re(G2)){var H2=$CLJS.$c(G2),rpa=$CLJS.ad(G2),spa=H2,tpa=$CLJS.E(H2);B2=rpa;C2=spa;D2=tpa}else{var upa=$CLJS.C(G2);$CLJS.mV(upa,q2);B2=$CLJS.D(G2);C2=null;D2=0}E2=0}else break}
for(var I2=$CLJS.A(new $CLJS.S(null,8,5,$CLJS.T,[$CLJS.gm,$CLJS.sm,$CLJS.im,$CLJS.rm,$CLJS.vJ,$CLJS.nK,$CLJS.LJ,$CLJS.wJ],null)),J2=null,K2=0,L2=0;;)if(L2<K2){var vpa=J2.$(null,L2);$CLJS.mV(vpa,t2);L2+=1}else{var M2=$CLJS.A(I2);if(M2){var N2=M2;if($CLJS.re(N2)){var O2=$CLJS.$c(N2),wpa=$CLJS.ad(N2),xpa=O2,ypa=$CLJS.E(O2);I2=wpa;J2=xpa;K2=ypa}else{var zpa=$CLJS.C(N2);$CLJS.mV(zpa,t2);I2=$CLJS.D(N2);J2=null;K2=0}L2=0}else break}
for(var P2=$CLJS.A(new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.yI,$CLJS.oJ,$CLJS.LI,$CLJS.aJ,$CLJS.Tk],null)),Q2=null,R2=0,S2=0;;)if(S2<R2){var Apa=Q2.$(null,S2);$CLJS.mV(Apa,p2);S2+=1}else{var T2=$CLJS.A(P2);if(T2){var U2=T2;if($CLJS.re(U2)){var V2=$CLJS.$c(U2),Bpa=$CLJS.ad(U2),Cpa=V2,Dpa=$CLJS.E(V2);P2=Bpa;Q2=Cpa;R2=Dpa}else{var Epa=$CLJS.C(U2);$CLJS.mV(Epa,p2);P2=$CLJS.D(U2);Q2=null;R2=0}S2=0}else break}
$CLJS.A1.o(null,$CLJS.OZ,function(a,b){var c=$CLJS.Rf($CLJS.OZ.g($CLJS.W0(a,b)));return $CLJS.m(c)?$CLJS.VH("Filtered by {0}",$CLJS.G([$CLJS.d2($CLJS.WH("and"),function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);a:for(var u=0;;)if(u<n){var v=$CLJS.be(l,u);v=$CLJS.b1.D(a,b,v,$CLJS.c1);q.add(v);u+=1}else{l=!0;break a}return l?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}q=$CLJS.C(g);
return $CLJS.nf($CLJS.b1.D(a,b,q,$CLJS.c1),f($CLJS.zd(g)))}return null}},null,null)}(c)}())])):null});
$CLJS.x1.o(null,s2,function(a,b,c,d){c=$CLJS.A(c);var e=$CLJS.C(c);c=$CLJS.D(c);$CLJS.C(c);var f=$CLJS.D(c);return $CLJS.d2(function(){var g=e instanceof $CLJS.N?e.S:null;switch(g){case "and":return $CLJS.WH("and");case "or":return $CLJS.WH("or");default:throw Error(["No matching clause: ",$CLJS.p.g(g)].join(""));}}(),function(){return function n(l){return new $CLJS.yf(null,function(){for(;;){var q=$CLJS.A(l);if(q){if($CLJS.re(q)){var u=$CLJS.$c(q),v=$CLJS.E(u),x=$CLJS.Bf(v);a:for(var y=0;;)if(y<
v){var B=$CLJS.be(u,y);B=$CLJS.b1.D(a,b,B,d);x.add(B);y+=1}else{u=!0;break a}return u?$CLJS.Ef($CLJS.Gf(x),n($CLJS.ad(q))):$CLJS.Ef($CLJS.Gf(x),null)}x=$CLJS.C(q);return $CLJS.nf($CLJS.b1.D(a,b,x,d),n($CLJS.zd(q)))}return null}},null,null)}(f)}())});
$CLJS.x1.o(null,q2,function(a,b,c,d){var e=$CLJS.A(c);c=$CLJS.C(e);e=$CLJS.D(e);$CLJS.C(e);var f=$CLJS.D(e);e=$CLJS.rg.h(function(g){return $CLJS.b1.D(a,b,g,d)},f);if($CLJS.F.h($CLJS.E(f),2))switch(f=$CLJS.J(e,0,null),e=$CLJS.J(e,1,null),c=c instanceof $CLJS.N?c.S:null,c){case "\x3d":return $CLJS.VH("{0} equals {1}",$CLJS.G([f,e]));case "!\x3d":return $CLJS.VH("{0} does not equal {1}",$CLJS.G([f,e]));default:throw Error(["No matching clause: ",$CLJS.p.g(c)].join(""));}else switch(f=$CLJS.C(e),e=$CLJS.d2($CLJS.WH("or"),
$CLJS.zd(e)),c=c instanceof $CLJS.N?c.S:null,c){case "\x3d":return $CLJS.VH("{0} equals any of {1}",$CLJS.G([f,e]));case "!\x3d":return $CLJS.VH("{0} does not equal any of {1}",$CLJS.G([f,e]));default:throw Error(["No matching clause: ",$CLJS.p.g(c)].join(""));}});
$CLJS.x1.o(null,t2,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);var f=$CLJS.J(c,2,null);c=$CLJS.J(c,3,null);f=$CLJS.b1.D(a,b,f,d);a=$CLJS.b1.D(a,b,c,d);e=e instanceof $CLJS.N?e.S:null;switch(e){case "\x3c":return $CLJS.VH("{0} is less than {1}",$CLJS.G([f,a]));case "\x3c\x3d":return $CLJS.VH("{0} is less than or equal to {1}",$CLJS.G([f,a]));case "\x3e":return $CLJS.VH("{0} is greater than {1}",$CLJS.G([f,a]));case "\x3e\x3d":return $CLJS.VH("{0} is greater than or equal to {1}",$CLJS.G([f,
a]));case "starts-with":return $CLJS.VH("{0} starts with {1}",$CLJS.G([f,a]));case "ends-with":return $CLJS.VH("{0} ends with {1}",$CLJS.G([f,a]));case "contains":return $CLJS.VH("{0} contains {1}",$CLJS.G([f,a]));case "does-not-contain":return $CLJS.VH("{0} does not contain {1}",$CLJS.G([f,a]));default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}});
$CLJS.x1.o(null,$CLJS.KI,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null),f=$CLJS.J(c,3,null);c=$CLJS.J(c,4,null);return $CLJS.VH("{0} is between {1} and {2}",$CLJS.G([$CLJS.b1.D(a,b,e,d),$CLJS.b1.D(a,b,f,d),$CLJS.b1.D(a,b,c,d)]))});
$CLJS.x1.o(null,$CLJS.VI,function(a,b,c,d){$CLJS.J(c,0,null);var e=$CLJS.J(c,1,null),f=$CLJS.J(c,2,null),g=$CLJS.J(c,3,null),l=$CLJS.J(c,4,null),n=$CLJS.J(c,5,null),q=$CLJS.J(c,6,null);c=$CLJS.J(c,7,null);return $CLJS.b1.D(a,b,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Lj,e,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.KI,e,f,q,l],null),new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.KI,e,g,n,c],null)],null),d)});
$CLJS.x1.o(null,p2,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);a=$CLJS.b1.D(a,b,c,d);e=e instanceof $CLJS.N?e.S:null;switch(e){case "is-null":return $CLJS.VH("{0} is empty",$CLJS.G([a]));case "not-null":return $CLJS.VH("{0} is not empty",$CLJS.G([a]));case "is-empty":return $CLJS.VH("{0} is empty",$CLJS.G([a]));case "not-empty":return $CLJS.VH("{0} is not empty",$CLJS.G([a]));case "not":return $CLJS.VH("not {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",
$CLJS.p.g(e)].join(""));}});$CLJS.x1.o(null,$CLJS.rK,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null),f=$CLJS.J(c,3,null);c=$CLJS.J(c,4,null);return $CLJS.VH("{0} is within {1}",$CLJS.G([$CLJS.b1.D(a,b,e,d),$CLJS.o2(f,c).toLowerCase()]))});$CLJS.x1.o(null,$CLJS.jY,function(a,b,c){a=$CLJS.Qf(c);return $CLJS.M.h(a,$CLJS.UI)});
$CLJS.D1.o(null,$CLJS.jY,function(a,b,c){b=$CLJS.Qf(c);a=$CLJS.M.h(b,$CLJS.BV);b=$CLJS.M.h(b,$CLJS.UI);return new $CLJS.k(null,2,[$CLJS.v1,$CLJS.jG(a),$CLJS.UI,b],null)});