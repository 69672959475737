var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var Uma;$CLJS.jY=new $CLJS.N("mbql.filter","operator","mbql.filter/operator",-1267834132);Uma=new $CLJS.N("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);for(var kY=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Lj,$CLJS.Bk],null)),lY=null,mY=0,nY=0;;)if(nY<mY){var Vma=lY.$(null,nY);$CLJS.zV(Vma,$CLJS.G([$CLJS.xu,$CLJS.zl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wt,new $CLJS.k(null,1,[$CLJS.qk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.JL],null)],null)],null)],null)]));nY+=1}else{var oY=$CLJS.A(kY);if(oY){var pY=oY;if($CLJS.re(pY)){var qY=$CLJS.$c(pY),Wma=$CLJS.ad(pY),
Xma=qY,Yma=$CLJS.E(qY);kY=Wma;lY=Xma;mY=Yma}else{var Zma=$CLJS.C(pY);$CLJS.zV(Zma,$CLJS.G([$CLJS.xu,$CLJS.zl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wt,new $CLJS.k(null,1,[$CLJS.qk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.JL],null)],null)],null)],null)]));kY=$CLJS.D(pY);lY=null;mY=0}nY=0}else break}$CLJS.xV($CLJS.Tk,$CLJS.G([$CLJS.xu,$CLJS.zl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.JL],null)]));
for(var rY=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,$CLJS.DK],null)),sY=null,tY=0,uY=0;;)if(uY<tY){var $ma=sY.$(null,uY);$CLJS.zV($ma,$CLJS.G([$CLJS.xu,$CLJS.zl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wt,new $CLJS.k(null,1,[$CLJS.qk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.NL],null)],null)],null)],null)]));uY+=1}else{var vY=$CLJS.A(rY);if(vY){var wY=vY;if($CLJS.re(wY)){var xY=$CLJS.$c(wY),ana=$CLJS.ad(wY),
bna=xY,cna=$CLJS.E(xY);rY=ana;sY=bna;tY=cna}else{var dna=$CLJS.C(wY);$CLJS.zV(dna,$CLJS.G([$CLJS.xu,$CLJS.zl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wt,new $CLJS.k(null,1,[$CLJS.qk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.NL],null)],null)],null)],null)]));rY=$CLJS.D(wY);sY=null;tY=0}uY=0}else break}
for(var yY=$CLJS.A(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.gm,$CLJS.sm,$CLJS.im,$CLJS.rm],null)),zY=null,AY=0,BY=0;;)if(BY<AY){var ena=zY.$(null,BY);$CLJS.xV(ena,$CLJS.G([$CLJS.xu,$CLJS.zl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null)]));BY+=1}else{var CY=$CLJS.A(yY);if(CY){var DY=CY;if($CLJS.re(DY)){var EY=$CLJS.$c(DY),fna=$CLJS.ad(DY),gna=EY,hna=$CLJS.E(EY);yY=fna;zY=gna;AY=hna}else{var ina=$CLJS.C(DY);$CLJS.xV(ina,$CLJS.G([$CLJS.xu,$CLJS.zl,
new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null)]));yY=$CLJS.D(DY);zY=null;AY=0}BY=0}else break}$CLJS.xV($CLJS.KI,$CLJS.G([$CLJS.xu,$CLJS.zl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null)]));
$CLJS.xV($CLJS.VI,$CLJS.G([$CLJS.xu,$CLJS.zl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.PL],null)]));
for(var FY=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yI,$CLJS.oJ],null)),GY=null,HY=0,IY=0;;)if(IY<HY){var jna=GY.$(null,IY);$CLJS.xV(jna,$CLJS.G([$CLJS.xu,$CLJS.zl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.KL],null)]));IY+=1}else{var JY=$CLJS.A(FY);if(JY){var KY=JY;if($CLJS.re(KY)){var LY=$CLJS.$c(KY),kna=$CLJS.ad(KY),lna=LY,mna=$CLJS.E(LY);FY=kna;GY=lna;HY=mna}else{var nna=$CLJS.C(KY);$CLJS.xV(nna,$CLJS.G([$CLJS.xu,$CLJS.zl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.KL],null)]));FY=$CLJS.D(KY);
GY=null;HY=0}IY=0}else break}
for(var MY=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.LI,$CLJS.aJ],null)),NY=null,OY=0,PY=0;;)if(PY<OY){var ona=NY.$(null,PY);$CLJS.xV(ona,$CLJS.G([$CLJS.xu,$CLJS.zl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.QL],null)]));PY+=1}else{var QY=$CLJS.A(MY);if(QY){var RY=QY;if($CLJS.re(RY)){var SY=$CLJS.$c(RY),pna=$CLJS.ad(RY),qna=SY,rna=$CLJS.E(SY);MY=pna;NY=qna;OY=rna}else{var sna=$CLJS.C(RY);$CLJS.xV(sna,$CLJS.G([$CLJS.xu,$CLJS.zl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.QL],null)]));MY=$CLJS.D(RY);
NY=null;OY=0}PY=0}else break}
for(var TY=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.aK,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.kk],null)],null),UY=$CLJS.A(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.vJ,$CLJS.nK,$CLJS.LJ,$CLJS.wJ],null)),VY=null,WY=0,XY=0;;)if(XY<WY){var YY=VY.$(null,XY);$CLJS.uV(YY,$CLJS.zl,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Lt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,YY],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gu,$CLJS.xL,TY],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.QL],
null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.QL],null)],null));XY+=1}else{var ZY=$CLJS.A(UY);if(ZY){var $Y=ZY;if($CLJS.re($Y)){var aZ=$CLJS.$c($Y),tna=$CLJS.ad($Y),una=aZ,vna=$CLJS.E(aZ);UY=tna;VY=una;WY=vna}else{var bZ=$CLJS.C($Y);$CLJS.uV(bZ,$CLJS.zl,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Lt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,bZ],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gu,$CLJS.xL,TY],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.QL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.QL],
null)],null));UY=$CLJS.D($Y);VY=null;WY=0}XY=0}else break}
$CLJS.uV($CLJS.rK,$CLJS.zl,new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.Lt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,$CLJS.rK],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gu,$CLJS.xL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yK,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.kk],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.IL],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bk,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Gt,$CLJS.nB,$CLJS.pD,$CLJS.SI],null),new $CLJS.S(null,2,
5,$CLJS.T,[$CLJS.gq,$CLJS.SL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.IV],null)],null));$CLJS.uV($CLJS.oI,$CLJS.zl,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Lt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,$CLJS.oI],null),$CLJS.xL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bk,$CLJS.rL,$CLJS.tL],null)],null));
$CLJS.kL(Uma,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Wl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vz,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,$CLJS.jY],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.BV,new $CLJS.S(null,17,5,$CLJS.T,[$CLJS.Gt,$CLJS.fm,$CLJS.DK,$CLJS.VI,$CLJS.KI,$CLJS.gm,$CLJS.im,$CLJS.sm,$CLJS.rm,$CLJS.yI,$CLJS.oJ,$CLJS.LI,$CLJS.aJ,$CLJS.LJ,$CLJS.wJ,$CLJS.vJ,$CLJS.nK],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.UI,$CLJS.Nl],null)],null));